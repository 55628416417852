<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-06 18:37:53
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-08 21:23:44
 * @Description: 请输入文件描述信息
 * @FilePath: \src\components\main\main.vue
-->
<template>
  <div class="kc-app">
    <!-- 顶部Navbar -->
    <!-- <div class="nav-1">
      <van-nav-bar
        :title="showNavBar.name"
        left-arrow
        :fixed="true"
        :placeholder="true"
        :z-index="500"
        :safe-area-inset-top="true"
        @click-left="router.go(-1)"
      />
    </div> -->

    <!-- 内容显示区 -->
    <div class="nav-2">
      <router-view></router-view>
    </div>

    <!-- Tabbar导航 -->
    <div class="nav-3">
      <van-tabbar
        v-model="showTabName"
        :fixed="true"
        :route="true"
        :placeholder="true"
        :safe-area-inset-bottom="true"
        :z-index="500"
      >
        <van-tabbar-item
          v-for="item in navBarConfig"
          :name="item.route"
          :key="item.id"
          :icon="item.icon"
          :to="'/' + item.route"
        >
          {{ item.name }}
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script src="./main.js"></script>
