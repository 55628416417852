/*
 * @Author: 智客云网络科技
 * @Date: 2022-02-06 19:32:24
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-08 21:20:47
 * @Description: 请输入文件描述信息
 * @FilePath: \src\components\main\main.js
 */

import { getCurrentInstance, onMounted, toRaw } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const internalInstance = getCurrentInstance();
    const utils = internalInstance.appContext.config.globalProperties.$utils;
    const store = useStore();
    const state = store.state;
    const version = state.version; //系统版本
    // const username = state.UserModule.username; //用户昵称
    const navBarConfig = state.MenuModule.navBarConfig; //导航菜单配置
    const showNavBar = state.MenuModule.showNavBar; //当前显示的菜单
    const showTabName = "";

    /**
     * 初始化模板
     */
    function init() {
      //打印版本等信息
      console.log("欢迎使用kc-app-vue，version：" + version);
      //设置水印
      //   this.$utils.setWatermark("飞鱼国际物流", this.username);
    }

    /**
     * 点击菜单时触发
     * @param {object} tab 菜单数据
     * @param {stying} index 菜单索引值
     */
    function tabSwitch(name) {
      if (utils.isNull(name)) {
        return false;
      }

      let tabs = toRaw(state.MenuModule.navBarConfig);
      console.log(tabs);
      let tab_index = utils.arrayGetIndex(tabs, "route", name);
      if (utils.isNull(tab_index)) {
        return false;
      }

      let tab = tabs[tab_index];
      if (utils.isNull(tab)) {
        return false;
      }

      store.commit("MenuModule/setShowNavBar", tab);
      console.log(state.MenuModule.showNavBar);
    }

    //Navbar左侧点击触发
    function leftBtn() {
      console.log("左侧点击");
    }

    //Navbar右侧点击触发
    function rightBtn() {
      console.log("右侧点击");
    }

    onMounted(() => {
      init();
    });

    return {
      tabSwitch,
      leftBtn,
      rightBtn,
      state,
      navBarConfig,
      showNavBar,
      showTabName,
    };
  },
};
